import * as React from "react"
import Header from "../layout/header"
import { Helmet, } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import '../index.scss';
import '../sites/all/themes/tickets/js/estacas/map.js';
import '../sites/all/themes/tickets/js/estacas/cards_rd'
import '../sites/all/themes/tickets/js/estacas/eventos'
import '../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/_analyticstagmanager';
import SEO from '../components/seo'
import '../stylesheets/scss/tickets.scss'
const IndexPage = () => {
        const scriptChunkMapping = `/*<![CDATA[*/window.___chunkMapping={{ "app": ["/app-338f6c80b582b6b32c62.js"] }};/*]]>*/`
        return (

                <body name="google" content="notranslate" className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
                        <HelmetProvider>
                                <Helmet>

                                        <link rel="preconnect" href="https://new.lasestacas.com" />
                                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                                        <link rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700&display=swap" rel='stylesheet' type='text/css' />
                                        <link rel="preload" href="https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700|Work+Sans:100,200,300,400,500,600,700&display=swap" rel='stylesheet' type='text/css' />
                                </Helmet>
                        </HelmetProvider>
                        <Header />
                </body>
        )
}
export default IndexPage
